<template>
    <div class="profile-content d-flex mt-4">
        <my-account-profile-avatar />
        <my-account-profile-info />
        <div
            v-if="!isMobile && generalData.seniority"
            class="separator"
        />
        <my-account-profile-thanks
            :seniority="generalData.seniority"
        />
        <my-account-profile-actions />
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    import MyAccountProfileActions from './MyAccountProfileActions';
    import MyAccountProfileAvatar from './MyAccountProfileAvatar';
    import MyAccountProfileInfo from './MyAccountProfileInfo';
    import MyAccountProfileThanks from './MyAccountProfileThanks';
    export default {
        components: {
            MyAccountProfileAvatar,
            MyAccountProfileInfo,
            MyAccountProfileThanks,
            MyAccountProfileActions,
        },
        name: 'MyAccountProfile',
        computed: {
            ...mapState('user', ['generalData']),
            isMobile() {
                return this.$screen.breakpoint == 'md';
            },
        },
    };
</script>
<style scoped lang="scss">
.profile-content {
  @include media-breakpoint-down(lg) {
    flex-wrap: wrap;
    gap: 16px;
  }
  @include media-breakpoint-down(sm) {
    justify-content: center;
  }
  .separator {
    width: 1px;
    background-color: $gray-thin;
    height: 100px;
    flex: none;
  }
}
</style>

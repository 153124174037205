<template>
    <div class="profile-stats mt-40">
        <div>
            <package-icon class="package" />
            <p>
                {{ getInfoText('page.myAccount.historyInfoText', generalData.orders) }}
            </p>
            <router-link
                v-slot="{ navigate }"
                :to="{ name: 'Comenzi' }"
                custom
            >
                <b-button
                    variant="primary"
                    class="sm"
                    @click="navigate"
                >
                    {{ $t('page.myAccount.seeHistoryButton') }}
                </b-button>
            </router-link>
        </div>
        <div>
            <heart-icon class="heart" />
            <p>
                {{
                    getInfoText('page.myAccount.wishlistInfoText', generalData.wishlists)
                }}
            </p>
            <router-link
                v-slot="{ navigate }"
                :to="{ name: 'Favorite' }"
                custom
            >
                <b-button
                    variant="primary"
                    class="sm"
                    @click="navigate"
                >
                    {{ $t('page.myAccount.seeWishlistButton') }}
                </b-button>
            </router-link>
        </div>
        <div>
            <gift-icon class="gift" />
            <p>
                {{
                    getInfoText('page.myAccount.voucherInfoText', generalData.vouchers)
                }}
            </p>
            <router-link
                v-slot="{ navigate }"
                :to="{ name: 'CupoaneleMele' }"
                custom
            >
                <b-button
                    variant="primary"
                    class="sm"
                    @click="navigate"
                >
                    {{ $t('page.myAccount.seeVoucherButton') }}
                </b-button>
            </router-link>
        </div>
        <div>
            <book-open-icon class="review" />
            <p>
                {{ getInfoText('page.myAccount.reviewInfoText', generalData.reviews) }}
            </p>
            <router-link
                v-slot="{ navigate }"
                :to="{ name: 'ReviewurileMele' }"
                custom
            >
                <b-button
                    variant="primary"
                    class="sm"
                    @click="navigate"
                >
                    {{ $t('page.myAccount.seeReviewButton') }}
                </b-button>
            </router-link>
        </div>
    </div>
</template>

<script>
    import BookOpenIcon from 'vue-feather-icons/icons/BookOpenIcon';
    import GiftIcon from 'vue-feather-icons/icons/GiftIcon';
    import HeartIcon from 'vue-feather-icons/icons/HeartIcon';
    import PackageIcon from 'vue-feather-icons/icons/PackageIcon';
    import { mapState } from 'vuex';

    export default {
        name: 'MyAccountStatistics',
        components: {
            HeartIcon,
            PackageIcon,
            GiftIcon,
            BookOpenIcon,
        },
        computed: {
            ...mapState('user', ['generalData']),
        },
        methods: {
            getInfoText(translationText, quantity) {
                return this.$t(
                    quantity == 1 ? translationText : translationText + 'Multi',
                    { number: quantity }
                );
            },
        },
    };
</script>
<style scoped lang="scss">
.profile-stats {
  display: flex;
  padding: 24px 0;
  flex-wrap: wrap;
  box-shadow: 0 0 0 1px $gray-thin;
  justify-content: space-between;
  @include media-breakpoint-down(lg) {
    gap: 0;
  }
  @include media-breakpoint-between(xm, md) {
    justify-content: space-between;
  }
  > div {
    padding: 0px 8px;
    text-align: center;
    margin-bottom: 16px;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    svg {
      height: 40px;
      width: 40px;
      &.package {
        stroke: $primary;
      }
      &.heart {
        stroke: $red;
      }
      &.gift {
        stroke: $secondary;
      }
      &.review {
        stroke: $green;
      }
    }
    p {
      font-size: 15px;
      color: $text;
      margin: 8px;
      font-weight: 600;

      @include media-breakpoint-down(xl) {
        font-size: 14px;
      }
    }
    .btn {
      margin: 0 auto;
      font-size: 12px;
    }
  }
}
</style>

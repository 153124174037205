<template>
    <div class="container">
        <loading-spinner
            v-if="showLoader"
            class="fixed"
        />
        <b-row>
            <sidebar :active="active" />
            <b-col
                cols="12"
                md="9"
                class="mtd-40"
            >
                <div :class="{ transparent: showLoader }">
                    <h4>
                        {{ $t('page.myAccount.heading') }}
                    </h4>
                    <my-account-profile />
                    <my-account-statistics />
                </div>
            </b-col>
            <edit-profile-data-modal v-if="showProfileModal" />
            <avatar-modal v-if="showAvatarModal" />
        </b-row>
    </div>
</template>

<script>
    import { i18n } from 'vue-lang-router';
    import { mapActions, mapState } from 'vuex';

    import Sidebar from '@/components/page/Sidebar';

    import LoadingSpinner from '../components/loaders/LoadingSpinner';
    import MyAccountProfile from './my-account/MyAccountProfile';
    import MyAccountStatistics from './my-account/MyAccountStatistics';

    const AvatarModal = () =>
        import(/* webpackChunkName: "avatar" */ '@/components/modals/AvatarModal');
    const EditProfileDataModal = () =>
        import(
            /* webpackChunkName: "edit-profile" */ '@/components/modals/EditProfileDataModal'
        );

    export default {
        name: 'MyAccount',
        components: {
            Sidebar,
            MyAccountProfile,
            MyAccountStatistics,
            LoadingSpinner,
            AvatarModal,
            EditProfileDataModal,
        },
        data() {
            return {
                active: false,
                showProfileModal: false,
                showAvatarModal: false,
            };
        },
        computed: {
            ...mapState('user', ['avatar', 'modal', 'loading', 'generalData']),
            lang() {
                return i18n.locale;
            },
            existData(){
                return Object.keys(this.generalData).length > 3;
            },
            showLoader(){
                return !this.existData && this.loading;
            }
        },
        methods: {
            ...mapActions('user', ['getUserData', 'getAvatar']),
            async loadData() {
                await this.getUserData();
                this.getAvatar();
            },
        },
        created() {
            this.loadData();
        },
        watch: {
            modal: {
                handler(modal) {
                    switch (modal.type) {
                    case 'profile':
                        this.showProfileModal = modal.show;
                        break;
                    case 'avatar':
                        this.showAvatarModal = modal.show;
                        break;
                    default:
                        break;
                    }
                },
                deep: true,
            },
        },
        metaInfo() {
            return {
                title: this.$t('meta.myAccount.profileTitle'),
                meta: [
                    {
                        name: 'robots',
                        content: 'noodp',
                    },
                    {
                        vmid: 'description',
                        name: 'description',
                        content: '',
                    },
                    {
                        vmid: 'keywords',
                        name: 'keywords',
                        content: '',
                    },
                    {
                        vmid: 'og:title',
                        property: 'og:title',
                        content: '',
                    },
                    {
                        vmid: 'og:url',
                        property: 'og:url',
                        content: '',
                    },
                    {
                        vmid: 'og:description',
                        property: 'og:description',
                        content: '',
                    },
                    {
                        vmid: 'og:image',
                        property: 'og:image',
                        content: '',
                    },
                ],
                link: [
                    {
                        rel: 'canonical',
                        href: '',
                    },
                ],
            };
        },
    };
</script>
<style scoped lang="scss">
@include media-breakpoint-down(sm) {
  h4 {
    text-align: center;
  }
}
</style>

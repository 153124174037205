<template>
    <div class="info">
        <div class="d-flex">
            <p class="sm">
                {{ $t('page.myAccount.aliasText') }}
            </p>
            <p class="sm">
                {{ generalData.name }}
            </p>
        </div>
        <div class="d-flex">
            <p class="sm">
                {{ $t('page.myAccount.emailAddressText') }}
            </p>
            <p class="sm">
                {{ generalData.email }}
            </p>
        </div>
        <div class="d-flex">
            <p class="sm">
                {{ $t('page.myAccount.nameText') }}
            </p>
            <p class="sm">
                {{ generalData.last_name }} {{ generalData.first_name }}
            </p>
        </div>
        <div class="d-flex">
            <p class="sm">
                {{ $t('page.myAccount.phoneText') }}
            </p>
            <p class="sm">
                {{ generalData.phone }}
            </p>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        name: 'MyAccountProfileInfo',
        computed: {
            ...mapState('user', ['generalData']),
        },
    };
</script>
<style scoped lang="scss">
.info {
  padding: 0px 16px;
  width: calc(50% - 16px - 100px / 3);
  @include media-breakpoint-between(sm, lg) {
    padding: 0;
    width: calc(60% - 16px - 50px);
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
  > div {
    padding-top: 4px;
    @include media-breakpoint-down(sm) {
      justify-content: center;
    }
    p {
      color: $text;
      &:first-of-type {
        width: 55px;
        color: $gray-dark;
        @include media-breakpoint-down(sm) {
          width: initial;
        }
      }
      &:last-of-type {
        max-width: calc(100% - 55px);
      }
    }
  }
}
</style>

<template>
    <div class="actions d-flex">
        <b-button
            variant="primary"
            class="sm"
            @click="openUpdateModal()"
        >
            {{ $t('page.myAccount.changeInfoButton') }}
        </b-button>
        <b-button
            variant="text"
            class="stroke sm"
            @click="openPasswordResetModal()"
        >
            {{ $t('page.myAccount.changePasswordButton') }}
        </b-button>
    </div>
</template>

<script>
    import { mapMutations, mapState } from 'vuex';
    export default {
        name: 'MyAccountProfileActions',
        computed: {
            ...mapState('user', ['generalData']),
        },
        methods: {
            ...mapMutations('general', ['setModal']),
            ...mapMutations('user', { setUserModal: 'setModal' }),
            openUpdateModal() {
                this.setUserModal({
                    show: true,
                    type: 'profile',
                });
            },
            openPasswordResetModal() {
                this.setModal({
                    show: true,
                    type: 'requestReset',
                    data: { resetEmail: this.generalData.email },
                });
            },
        },
    };
</script>
<style scoped lang="scss">
.actions {
  flex-direction: column;
  gap: 12px;
  min-width: 150px;
  width: calc(25% - 16px);
  justify-content: center;
  @include media-breakpoint-down(lg) {
    margin-top: 12px;
    justify-content: start;
    width: 100%;
  }
  @include media-breakpoint-down(sm) {
    justify-content: center;
  }
  @include media-breakpoint-between(xm, lg) {
    flex-direction: row;
  }
  .btn {
    margin: 0;
  }
}
</style>

<template>
    <div class="thanks">
        <p
            v-if="seniority"
            class="sm"
        >
            {{ $t('page.myAccount.thanksText') }}
        </p>
        <h6 v-if="seniority">
            {{ seniority }}
        </h6>
    </div>
</template>

<script>
    export default {
        name: 'MyAccountProfileThanks',
        props: {
            seniority: {
                type: String,
                default: '',
            },
        },
    };
</script>
<style scoped lang="scss">
.thanks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 8px;
  padding: 0px 16px;
  width: calc(25% - 16px);
  @include media-breakpoint-between(sm, lg) {
    width: calc(40% - 50px - 16px);
    padding: 0;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
  * {
    color: $text;
  }
}
</style>

<template>
    <div class="avatar-layout">
        <div
            class="avatar-image"
            :class="{ hasMonogram: !avatar && monogram }"
            @click="openAvatarModal()"
        >
            <img
                v-if="!monogram"
                :src="avatarUrl"
                alt="avatar"
            >
            <span
                v-if="!avatar && monogram"
                class="monogram"
            >
                {{ monogram }}
            </span>
            <span class="edit">
                <img
                    src="/images/fe_pencil.svg"
                    :alt="$t('modal.confirm.editButton')"
                >
            </span>
        </div>
    </div>
</template>

<script>
    import { mapMutations, mapState } from 'vuex';
    export default {
        name: 'MyAccountProfileAvatar',
        computed: {
            ...mapState('user', ['avatar', 'generalData']),
            monogram() {
                if (this.avatar || !this.generalData.first_name || !this.generalData.last_name) {
                    return null;
                }
                return (
                    ' ' + this.generalData.first_name.charAt(0) +
                    this.generalData.last_name.charAt(0)
                );
            },
            avatarUrl(){
                return !this.avatar ? '/images/admin.webp' : this.avatar;
            }
        },
        methods: {
            ...mapMutations('user', ['setModal']),
            openAvatarModal() {
                this.setModal({
                    show: true,
                    type: 'avatar',
                });
            },
        },
    };
</script>
<style scoped lang="scss">
.avatar-layout {
  height: 100px;
  min-width: 100px;
  width: 100px;
  overflow: hidden;
  border-radius: 50%;
  .avatar-image {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    &.hasMonogram {
      border: 3px solid $text;
      background-color: $white;
      border-radius: 50%;
    }
    svg {
      height: 42px;
      width: 42px;
    }
    .edit {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 16px;
      background-color: $text;
      box-shadow: none;
      text-align: center;
      img {
        width: 14px;
        height: 14px;
        vertical-align: initial;
        margin-bottom: 2px;
      }
      input {
        display: none;
      }
    }
    img {
      border-radius: 50%;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .monogram {
      height: 94px;
      display: flex;
      align-items: center;
      letter-spacing: 6px;
      text-indent: 4px;
      text-transform: uppercase;
      color: #414d59;
      font-size: 40px;
      font-weight: 700;
      width: 60px;
    }
  }
}
</style>
